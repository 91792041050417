import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import getNavigationRoutes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import ApiService from "api/ApiService.js";
import Button from "components/CustomButtons/Button.js";
import stylesNavbar from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import CompanyTheme from "utils/CompanyTheme";
import { useTranslation } from "react-i18next";

var ps;

const useStyles = makeStyles(styles);
const useStylesNavbar = makeStyles(stylesNavbar);

export default function Dashboard(props) {
  const { t } = useTranslation();
  const routes = getNavigationRoutes();
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const image = require("assets/img/unisot-bg-navbar.jpeg");
  const color = "blue";
  const bgColor = "black";
  // const [hasImage, setHasImage] = React.useState(true);
  const logo = CompanyTheme.getLogo();
  const miniLogo = CompanyTheme.getMiniLogo();

  // styles
  const classes = useStyles();
  const classesNavbar = useStylesNavbar();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const routeDetails = {
    "/auth/pricing-page": {
      path: [{ path: "", title: t("pricing-plan") }],
      searchPath: [],
    },
    "/auth/login-page": {
      path: [{ path: "", title: t("login-page") }],
      searchPath: [],
    },
    "/auth/lock-screen-page": {
      path: [{ path: "", title: t("lock-screen-page") }],
      searchPath: [],
    },
    "/auth/error-page": {
      path: [{ path: "", title: t("error-page") }],
      searchPath: [],
    },
    "/auth/register-page": {
      path: [{ path: "", title: t("register-page") }],
      searchPath: [],
    },
    "/auth/validate-email": {
      path: [{ path: "", title: t("validate-email") }],
      searchPath: [],
    },
    "/auth/reset-password-email": {
      path: [{ path: "", title: t("reset-password") }],
      searchPath: [],
    },
    "/auth/reset-password": {
      path: [{ path: "", title: t("reset-password") }],
      searchPath: [],
    },
    "/auth/redirect-page": {
      path: [{ path: "", title: t("redirect-page") }],
      searchPath: [],
    },
    "/admin/admin-users": {
      path: [{ path: "", title: t("users") }],
      searchPath: [],
    },
    "/admin/user-profile": {
      path: [{ path: "", title: t("user-profile") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "", title: t("user-profile") },
      ],
    },
    "/admin/dashboard": {
      path: [{ path: "", title: t("dashboard") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "", title: t("user-dashboard") },
      ],
    },
    "/admin/admin-services": {
      path: [{ path: "", title: t("services") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "", title: t("user-services") },
      ],
      searchOrgPath: [
        { path: "/admin/admin-organizations", title: t("organizations") },
        {
          path: "/admin/organization-profile",
          title: t("organization-profile"),
        },
        { path: "", title: t("organization-services") },
      ],
    },
    "/admin/admin-endpoints": {
      path: [{ path: "", title: t("service-endpoints") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "/admin/admin-services", title: t("user-services") },
        { path: "", title: t("user-endpoints") },
      ],
      searchOrgPath: [
        { path: "/admin/admin-organizations", title: t("organizations") },
        {
          path: "/admin/organization-profile",
          title: t("organization-profile"),
        },
        { path: "/admin/admin-services", title: t("organization-services") },
        { path: "", title: t("organization-endpoints") },
      ],
    },
    "/admin/user-organizations": {
      path: [{ path: "", title: t("organizations") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "", title: t("user-organizations") },
      ],
    },
    "/admin/user-documents": {
      path: [{ path: "", title: t("documents") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "", title: t("user-documents") },
      ],
      searchOrgPath: [
        { path: "/admin/admin-organizations", title: t("organizations") },
        {
          path: "/admin/organization-profile",
          title: t("organization-profile"),
        },
        { path: "", title: t("organization-documents") },
      ],
    },
    "/admin/user-subscriptions": {
      path: [{ path: "", title: t("subscriptions") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "", title: t("user-subscriptions") },
      ],
      searchOrgPath: [
        { path: "/admin/admin-organizations", title: t("organizations") },
        {
          path: "/admin/organization-profile",
          title: t("organization-profile"),
        },
        { path: "", title: t("organization-subscriptions") },
      ],
    },
    "/admin/user-billings": {
      path: [{ path: "", title: t("billings") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "", title: t("user-billings") },
      ],
      searchOrgPath: [
        { path: "/admin/admin-organizations", title: t("organizations") },
        {
          path: "/admin/organization-profile",
          title: t("organization-profile"),
        },
        { path: "", title: t("organization-billings") },
      ],
    },
    "/admin/user-lookups": {
      path: [{ path: "", title: t("lookups") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "", title: t("user-lookups") },
      ],
      searchOrgPath: [
        { path: "/admin/admin-organizations", title: t("organizations") },
        {
          path: "/admin/organization-profile",
          title: t("organization-profile"),
        },
        { path: "", title: t("organization-lookups") },
      ],
    },
    "/admin/user-keys": {
      path: [{ path: "", title: t("keys") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "", title: t("user-keys") },
      ],
    },
    "/admin/user-key-selling-rules": {
      path: [{ path: "", title: t("key-selling-rules") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "", title: t("user-key-selling-rules") },
      ],
    },
    "/admin/user-ssi": {
      path: [{ path: "", title: t("ssi") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "", title: t("ssi") },
      ],
      searchOrgPath: [
        { path: "/admin/admin-organizations", title: t("organizations") },
        {
          path: "/admin/organization-profile",
          title: t("organization-profile"),
        },
        { path: "", title: t("ssi") },
      ],
    },
    "/admin/job-inspector": {
      path: [{ path: "", title: t("job-inspector") }],
      searchPath: [],
    },
    "/admin/admin-organizations": {
      path: [{ path: "", title: t("organizations") }],
      searchPath: [],
    },
    "/admin/organization-profile": {
      path: [{ path: "", title: t("organizations") }],
      searchPath: [
        { path: "/admin/admin-organizations", title: t("organizations") },
        { path: "", title: t("organization-profile") },
      ],
      searchOrgPath: [
        { path: "/admin/admin-organizations", title: t("organizations") },
        { path: "", title: t("organization-profile") },
      ],
    },
    "/admin/admin-subscriptions": {
      path: [{ path: "", title: t("subscriptions") }],
      searchPath: [],
    },
    "/admin/admin-role-configuration": {
      path: [{ path: "", title: t("role-configuration-0") }],
      searchPath: [
        { path: "/admin/admin-roles", title: t("roles") },
        { path: "", title: t("role-configuration-0") },
      ],
    },
    "/admin/admin-deployments": {
      path: [{ path: "", title: t("deployments") }],
      searchPath: [],
    },
    "/admin/admin-capabilities": {
      path: [{ path: "", title: t("capabilities") }],
      searchPath: [],
    },
    "/admin/admin-roles": {
      path: [{ path: "", title: t("roles") }],
      searchPath: [],
    },
    "/admin/admin-pricing-plans": {
      path: [{ path: "", title: t("pricing-plans") }],
      searchPath: [],
    },
    "/admin/admin-pricing-lists": {
      path: [{ path: "", title: t("pricing-lists") }],
      searchPath: [],
    },
    "/admin/admin-pricing-subscriptions": {
      path: [{ path: "", title: t("pricing-plan-subscriptions") }],
      searchPath: [
        { path: "/admin/admin-pricing-plans", title: t("pricing-plans") },
        { path: "", title: t("pricing-plan-subscriptions") },
      ],
    },
    "/admin/admin-key-transactions": {
      path: [{ path: "", title: t("key-transactions") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "", title: t("key-transactions") },
      ],
    },
    "/admin/admin-transactions": {
      path: [{ path: "", title: t("transactions") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "", title: t("transactions") },
      ],
    },
    "/admin/admin-external-storage": {
      path: [{ path: "", title: t("external-storage") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "", title: t("external-storage") },
      ],
    },
    "/admin/admin-system-overview": {
      path: [{ path: "", title: t("system-overview") }],
      searchPath: [],
    },
    "/admin/admin-system-configuration": {
      path: [{ path: "", title: t("system-configuration") }],
      searchPath: [],
    },
    "/admin/admin-deployment-machine": {
      path: [{ path: "", title: t("deployment-machine") }],
      searchPath: [
        { path: "/admin/admin-system-overview", title: t("system-overview") },
        { path: "", title: t("deployment-machine") },
      ],
    },
    "/admin/user-audit-logs": {
      path: [{ path: "", title: t("audit-logs") }],
      searchPath: [
        { path: "/admin/admin-users", title: t("users") },
        { path: "/admin/user-profile", title: t("user-profile") },
        { path: "", title: t("user-audit-logs") },
      ],
      searchOrgPath: [
        { path: "/admin/admin-organizations", title: t("organizations") },
        {
          path: "/admin/organization-profile",
          title: t("organization-profile"),
        },
        { path: "", title: t("organization-audit-logs") },
      ],
    },
    "/admin/app-translations": {
      path: [{ path: "", title: t("app-translations") }],
      searchPath: [],
    },
  };

  const getBreadcrumbsRoute = () => {
    const path = window.location.pathname;
    const search = window.location.search;
    const isOrganization = search.split("&").length === 3;
    const searchParamsExist = search.length > 0;
    let mapRoutes = [];
    if (routeDetails[path]) {
      mapRoutes = routeDetails[path].path;
      if (searchParamsExist) {
        if (
          isOrganization &&
          routeDetails[path].searchOrgPath &&
          routeDetails[path].searchOrgPath.length > 0
        ) {
          mapRoutes = routeDetails[path].searchOrgPath;
        } else if (
          routeDetails[path].searchPath &&
          routeDetails[path].searchPath.length > 0
        ) {
          mapRoutes = routeDetails[path].searchPath;
        }
      }
    }
    return (
      <React.Fragment>
        {mapRoutes?.map((bc, index) => {
          return (
            <React.Fragment key={index}>
              <Button
                className={classesNavbar.breadcrumb}
                color="transparent"
                disabled
              >
                {bc.title}
              </Button>
              {index !== mapRoutes.length - 1 && <span>-</span>}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={ApiService.theme}
        logo={logo}
        miniLogo={miniLogo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div style={{ height: "50px", background: "#fff", marginTop: "70px" }}>
          <div className={classesNavbar.flex} style={{ paddingLeft: "15px" }}>
            {/* Here we create navbar brand, based on route name */}
            {getBreadcrumbsRoute()}
          </div>
        </div>
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
        )}
        {getRoute() ? <Footer fluid /> : null}
      </div>
    </div>
  );
}
