import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import TranslateIcon from "@material-ui/icons/Translate";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import AlbumIcon from "@material-ui/icons/Album";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Muted from "components/Typography/Muted.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const defaultLanguage = "english";

const AppTranslations = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const alertClasses = useAlertStyles();
  const formClasses = useStylesForm();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);

  // Dropdown selected values
  const [applicationName, setApplicationName] = React.useState(
    ApiService.applicationNames.dpp
  );
  const [applicationType, setApplicationType] = React.useState(
    ApiService.applicationTypes.frontend
  );
  const [languageType, setLanguageType] = React.useState(defaultLanguage);
  const [toTranslate, setToTranslate] = React.useState([]);
  const [filters, setFilters] = React.useState({
    applicationName: ApiService.applicationNames.dpp,
    applicationType: ApiService.applicationTypes.frontend,
    languageType: defaultLanguage,
    toTranslate,
  });

  // Submit button clicked
  const [submitClicked, setSubmitClicked] = React.useState(true);
  const [selectDeselect, setSelectDeselect] = React.useState(true);

  // Statistics results
  const [translations, setTranslations] = React.useState([]);

  // Translation modal
  const [showTranslationModal, setShowTranslationModal] = React.useState(false);
  const [modalEntryId, setModalEntryId] = React.useState("");
  const [modalToken, setModalToken] = React.useState("");
  const [modalSource, setModalSource] = React.useState("");
  const [modalLanguage, setModalLanguage] = React.useState("");
  const [modalValue, setModalValue] = React.useState("");

  // Import modal
  const [showImportModal, setShowImportModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("edit")
  );
  const [modalSelectedFile, setModalSelectedFile] = React.useState(undefined);
  const [checkboxes, setCheckboxes] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const modalFileInput = React.createRef();

  const clearModalState = () => {
    setShowImportModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("edit"));
    setModalSelectedFile(undefined);
    setShowTranslationModal(false);
    setModalEntryId("");
    setModalToken("");
    setModalSource("");
    setModalLanguage("");
    setModalValue("");
  };

  const getDescription = (addLanguage, addTranslate) => {
    let output = "";
    if (addLanguage) {
      output = `${capitalizeFirstLetter(filters.languageType)} `;
    }
    output = `${output}${
      filters.applicationName
    } ${filters.applicationType.toLowerCase()} ${t("translations")}`;
    if (addTranslate && filters.toTranslate.length > 0) {
      output = `${output} (${t("translate")})`;
    }
    return output;
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleImportTranslations = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleSubmit();
      return clearModalState();
    }

    const params = {
      file: modalSelectedFile,
      name: filters.applicationName,
      type: filters.applicationType,
    };

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("uploading"));

    try {
      const responseData = await ApiService.importTranslations(params);
      setModalProgress(100);
      return showSuccessModalMessage(responseData.message);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
  };

  const handleExportTranslations = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("export")}`}
        onConfirm={() => {
          exportTranslations();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{getDescription(false, false)}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleMultipleAiTranslate = () => {
    const entries = Object.keys(checkboxes).filter(
      (id) => checkboxes[id].checked
    );
    if (entries.length === 0) return;
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("ai-translate")}?`}
        onConfirm={() => {
          aiTranslation(entries);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${entries.length} ${t("entries")}`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleEditTranslation = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleSubmit();
      return clearModalState();
    }

    const params = {
      entryId: modalEntryId,
      language: modalLanguage,
      translation: modalValue,
    };

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("editing"));

    try {
      const responseData = await ApiService.addTranslation(params);
      setModalProgress(100);
      return showSuccessModalMessage(responseData.message);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
  };

  const exportTranslations = async () => {
    setAlert(null);
    try {
      const params = {
        name: filters.applicationName,
        type: filters.applicationType,
      };
      const responseData = await ApiService.exportTranslations(params);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = `${getDescription(false, false)}.zip`.replace(/ /g, "_");
      a.click();
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleSubmit();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleSubmit = () => {
    setSelectDeselect(true);
    setSubmitClicked((prevCheck) => !prevCheck);
  };

  const handleSelectDeselect = () => {
    if (rows.length === 0) {
      return;
    }
    const selected = selectDeselect;
    const checkboxes_ = { ...checkboxes };
    let updated = false;
    // eslint-disable-next-line no-unused-vars
    for (const id of rows) {
      if (!checkboxes_[id].disabled) {
        checkboxes_[id].checked = selected;
        updated = true;
      }
    }
    if (!updated) {
      return;
    }
    setCheckboxes(checkboxes_);
    setSelectDeselect(!selected);
  };

  const aiTranslation = async (entries, language = undefined) => {
    try {
      await ApiService.autoTranslate({ entries });
      operationResult(true, t("ai-translation-success"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const handleAiTranslate = (entryId, token, language) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("ai-translate")}?`}
        onConfirm={() => {
          aiTranslation([entryId], language);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{token}</b>
        </p>
      </SweetAlert>
    );
  };

  const defaultTranslation = async (entryId, language) => {
    setAlert(null);
    try {
      const params = {
        entryId,
        language,
      };
      await ApiService.deleteTranslation(params);
      operationResult(true, t("default-translation-success"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const handleDefaultTranslation = (entryId, token, language) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("default-translation")}?`}
        onConfirm={() => {
          defaultTranslation(entryId, language);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{token}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleReturnData = (data) => {
    const english = ApiService.languageTypes.english;
    const language = ApiService.languageTypes[filters.languageType];
    const filter = filters.toTranslate.length > 0;
    const translationToken = ApiService.translationToken;

    const output = [];
    // eslint-disable-next-line no-unused-vars
    for (const elem of data) {
      let source, value, translator;
      // eslint-disable-next-line no-unused-vars
      for (const entry of elem.translations) {
        if (entry.language === english) {
          source = entry.translation;
        }
        if (entry.language === language) {
          translator = entry.translator;
          value = entry.translation;
        }
        if (value && source) break;
      }
      const translationTokenSet = value === translationToken;
      const englishLanguage = language === english;
      if (!filter || translationTokenSet) {
        output.push({
          _id: elem._id,
          token: elem.token,
          english: source,
          translation: value,
          translator,
          actions: (
            <div className="actions-right">
              <Tooltip title={t("select-deselect")}>
                <Checkbox
                  color="default"
                  key={elem._id}
                  checked={checkboxes[elem._id] && checkboxes[elem._id].checked}
                  className={classes.positionAbsolute}
                  tabIndex={-1}
                  disabled={
                    checkboxes[elem._id] && checkboxes[elem._id].disabled
                  }
                  onClick={() => {
                    const checkboxesUpdate = { ...checkboxes };
                    checkboxesUpdate[elem._id] = {
                      ...checkboxes[elem._id],
                      checked: !checkboxes[elem._id].checked,
                    };
                    setCheckboxes(checkboxesUpdate);
                  }}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              </Tooltip>
              <Tooltip title={t("edit-translation")}>
                <Button
                  justIcon
                  round
                  simple
                  color="rose"
                  className="edit"
                  onClick={() => {
                    setModalEntryId(elem._id);
                    setModalToken(elem.token);
                    setModalSource(source);
                    setModalLanguage(language);
                    setModalValue(value);
                    setConfirmModalButtonText(t("edit"));
                    setShowTranslationModal(true);
                  }}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
              {translationTokenSet && !englishLanguage && (
                <Tooltip title={t("ai-translate")}>
                  <Button
                    justIcon
                    round
                    simple
                    color="rose"
                    className="edit"
                    onClick={() => {
                      handleAiTranslate(elem._id, elem.token, language);
                    }}
                  >
                    <AlbumIcon />
                  </Button>
                </Tooltip>
              )}
              {!translationTokenSet && !englishLanguage && (
                <Tooltip title={t("default-translation")}>
                  <Button
                    justIcon
                    color="rose"
                    simple
                    className="remove"
                    onClick={() => {
                      handleDefaultTranslation(elem._id, elem.token, language);
                    }}
                  >
                    <ClearIcon />
                  </Button>
                </Tooltip>
              )}
            </div>
          ),
        });
      }
    }
    return output;
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getTranslations = async (signal = undefined) => {
      try {
        const params = {
          name: applicationName,
          type: applicationType,
          sortBy: "token:asc",
        };
        const responseData = await ApiService.getTranslationEntries(
          params,
          signal
        );

        const checkboxes_ = {};
        const english = ApiService.languageTypes.english;
        const language = ApiService.languageTypes[languageType];
        const translationToken = ApiService.translationToken;
        // eslint-disable-next-line no-unused-vars
        for (const elem of responseData) {
          // eslint-disable-next-line no-unused-vars
          for (const entry of elem.translations) {
            if (entry.language === language) {
              checkboxes_[elem._id] = {
                disabled:
                  language === english ||
                  entry.translation !== translationToken,
                checked: false,
              };
              break;
            }
          }
        }
        setFilters({
          applicationName,
          applicationType,
          languageType,
          toTranslate,
        });
        setCheckboxes(checkboxes_);
        setTranslations(responseData);
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal, true, false);
        await getTranslations();
        setLoading(false);
        setSubmitClicked(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && submitClicked && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    applicationName,
    applicationType,
    languageType,
    toTranslate,
    submitClicked,
  ]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showImportModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            modalFileInput.current.value = "";
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>
            {t("import")} {getDescription(false, false)}
          </h4>
        </DialogTitle>
        <form
          onSubmit={(event) => {
            handleImportTranslations(event);
            modalFileInput.current.value = "";
          }}
        >
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12}>
                <div className="picture-container">
                  <div className="picture">
                    <CloudUploadIcon
                      style={{
                        width: "2.5em",
                        height: "2.5em",
                        color: roseColor[0],
                        paddingTop: "1em",
                      }}
                    />
                    <label
                      htmlFor="files"
                      className="btn"
                      style={{ display: "block" }}
                    >
                      {t("select-file")}
                    </label>
                    <input
                      type="file"
                      id="files"
                      ref={modalFileInput}
                      disabled={showModalProgress}
                      onChange={(e) => {
                        setModalSelectedFile(e.target.files[0]);
                      }}
                    />
                  </div>
                  <h6 className="description">
                    {modalSelectedFile && modalSelectedFile.name}
                  </h6>
                  <h6 className="description">
                    {modalSelectedFile && `Size: ${modalSelectedFile.size}`}
                  </h6>
                </div>
              </GridItem>
            </GridContainer>
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button
              onClick={() => {
                modalFileInput.current.value = "";
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showTranslationModal}
        transition={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{t("translate")}</h4>
        </DialogTitle>
        <form
          onSubmit={(event) => {
            handleEditTranslation(event);
          }}
        >
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("token")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="entry-token"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalToken,
                    disabled: true,
                  }}
                  helpText={t("token")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("english")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="english-translation"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalSource,
                    disabled: true,
                  }}
                  helpText={t("english")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("translation")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="entry-translation"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalValue,
                    onChange: (e) => {
                      setModalValue(e.target.value);
                    },
                  }}
                  helpText={t("translation")}
                />
              </GridItem>
            </GridContainer>
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("close")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <legend>{t("filters")}</legend>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={3}>
          <FormControl fullWidth className={formClasses.selectFormControl}>
            <InputLabel
              htmlFor="application-name"
              className={formClasses.selectLabel}
            >
              {t("name")}
            </InputLabel>
            <Select
              value={applicationName}
              onChange={(e) => {
                setApplicationName(e.target.value);
              }}
              MenuProps={{
                className: formClasses.selectMenu,
              }}
              classes={{
                select: formClasses.select,
              }}
              inputProps={{
                name: "applicationName",
                id: "application-name",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: formClasses.selectMenuItem,
                }}
              >
                {t("name")}
              </MenuItem>
              {Object.values(ApiService.applicationNames).map((entry) => {
                return (
                  <MenuItem
                    key={entry}
                    classes={{
                      root: formClasses.selectMenuItem,
                      selected: formClasses.selectMenuItemSelectedMultiple,
                    }}
                    value={entry}
                  >
                    {entry}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={3}>
          <FormControl fullWidth className={formClasses.selectFormControl}>
            <InputLabel
              htmlFor="application-type"
              className={formClasses.selectLabel}
            >
              {t("type")}
            </InputLabel>
            <Select
              value={applicationType}
              onChange={(e) => {
                setApplicationType(e.target.value);
              }}
              MenuProps={{
                className: formClasses.selectMenu,
              }}
              classes={{
                select: formClasses.select,
              }}
              inputProps={{
                name: "applicationType",
                id: "application-type",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: formClasses.selectMenuItem,
                }}
              >
                {t("type")}
              </MenuItem>
              {Object.values(ApiService.applicationTypes).map((entry) => {
                return (
                  <MenuItem
                    key={entry}
                    classes={{
                      root: formClasses.selectMenuItem,
                      selected: formClasses.selectMenuItemSelectedMultiple,
                    }}
                    value={entry}
                  >
                    {entry}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={2}>
          <FormControl fullWidth className={formClasses.selectFormControl}>
            <InputLabel
              htmlFor="language-type"
              className={formClasses.selectLabel}
            >
              {t("language")}
            </InputLabel>
            <Select
              value={languageType}
              onChange={(e) => {
                setLanguageType(e.target.value);
              }}
              MenuProps={{
                className: formClasses.selectMenu,
              }}
              classes={{
                select: formClasses.select,
              }}
              inputProps={{
                name: "languageType",
                id: "language-type",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: formClasses.selectMenuItem,
                }}
              >
                {t("language")}
              </MenuItem>
              {Object.keys(ApiService.languageTypes).map((entry) => {
                return (
                  <MenuItem
                    key={entry}
                    classes={{
                      root: formClasses.selectMenuItem,
                      selected: formClasses.selectMenuItemSelectedMultiple,
                    }}
                    value={entry}
                  >
                    {entry.toUpperCase()}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={2} style={{ marginTop: "13px" }}>
          <div className={formClasses.checkboxAndRadio}>
            <FormControlLabel
              style={{ float: "left" }}
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={() => {
                    const currentIndex = toTranslate.indexOf(1);
                    const newChecked = [...toTranslate];
                    if (currentIndex === -1) {
                      newChecked.push(1);
                    } else {
                      newChecked.splice(currentIndex, 1);
                    }
                    setToTranslate(newChecked);
                  }}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot,
                  }}
                  checked={toTranslate.length > 0}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot,
              }}
              label={t("to-translate")}
            />
          </div>
        </GridItem>
        <GridItem xs={2}>
          <Button
            simple
            color="rose"
            className="remove"
            onClick={() => handleSubmit()}
            style={{ marginTop: "28px" }}
          >
            {t("submit")}
          </Button>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <TranslateIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {getDescription(true, true)}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    setConfirmModalButtonText(t("upload"));
                    setShowImportModal(true);
                  }}
                >
                  {t("import")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    handleExportTranslations();
                  }}
                >
                  {t("export")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    handleSelectDeselect();
                  }}
                >
                  {selectDeselect ? t("select") : t("deselect")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    handleMultipleAiTranslate();
                  }}
                >
                  {t("ai-translate")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={handleReturnData(translations)}
                filterable
                columns={[
                  {
                    Header: t("token"),
                    accessor: "token",
                  },
                  {
                    Header: t("english"),
                    accessor: "english",
                  },
                  {
                    Header: t("translation"),
                    accessor: "translation",
                  },
                  {
                    Header: t("translator"),
                    accessor: "translator",
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
                onFetchData={(state) => {
                  const activeRows = state.sortedData
                    .slice(
                      state.page * state.pageSize,
                      (state.page + 1) * state.pageSize
                    )
                    .map((entry) => entry._original._id);
                  setRows(activeRows);
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

AppTranslations.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AppTranslations;
